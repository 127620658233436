import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { queryBuilder } from '@metromarkets/sdk-17';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { deserialize } from 'serialize-ts/dist';
import {
  AFTERSALES_ADMIN_API,
  ALL_ORDERS_API,
  ORDERS_API,
  ORDERS_API_V2,
} from '@sales-orders-app/modules/orders/constants/api';
import { Order } from '@sales-orders-app/modules/orders/models/order.model';
import { PaginatedResponse, RequestParams } from '~shared/model';
import { OrderReturnLabelAvailable } from '@sales-orders-app/modules/orders/models/order-return-label-available.model';
import { FeatureFlagService } from '~core/modules/feature-flag';
import { FeatureToggleEnum } from '~shared/constants';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root',
})
export class OrdersService {

  private orderAPIFeatureFlagStatus = false;

  constructor(
    private http: HttpClient,
    private oAuthService: OAuthService,
    private featureFlagService: FeatureFlagService
  ) {
    this.featureFlagService
      .isFeatureEnabled(FeatureToggleEnum.FF_TEMP_GET_ORDERS_V2)
      .subscribe(res => (this.orderAPIFeatureFlagStatus = res));
  }

  getOrders(requestParams: RequestParams): Observable<PaginatedResponse<Order>> {
    const params = queryBuilder.toParams(requestParams) as HttpParams;

    if (this.orderAPIFeatureFlagStatus) {
      return this.http.get<PaginatedResponse<Order>>(ORDERS_API_V2, { params }).pipe(
        map(response => ({
          totalCount: response.totalCount,
          items: response.items.map(i => deserialize(i, Order)),
        })),
      );
    }

    return this.http.get<PaginatedResponse<Order>>(ORDERS_API, { params }).pipe(
      map(response => ({
        totalCount: response.totalCount,
        items: response.items.map(i => deserialize(i, Order)),
      })),
    );
  }

  getAllOrders(requestParams: RequestParams, totalCount: number): Observable<Blob> {
    const params = queryBuilder.toParams(
      queryBuilder.toParams({
        ...requestParams,
        limit: totalCount,
        responseType: 'blob',
        observe: 'response',
      }),
    ) as HttpParams;

    return this.http.get<Blob>(ALL_ORDERS_API, { params }).pipe(map(response => response));
  }

  getReturnLabel(orderNumber: string): Observable<HttpResponse<any>> {
    const url = `${AFTERSALES_ADMIN_API}/sms/return-labels`;
    const headers = this.requestHeader(`Bearer ${this.oAuthService.getAccessToken()}`);
    return this.http.post(
      url,
      {orderNumber},
      {headers, observe: 'response', responseType: 'blob'},
    );
  }

  isReturnLabelAvailable(orderNumber: string): Observable<boolean> {
    const url = `${AFTERSALES_ADMIN_API}/sms/return-labels/available`;
    const headers = this.requestHeader(`Bearer ${this.oAuthService.getAccessToken()}`);
    return this.http.post<OrderReturnLabelAvailable>(url, {orderNumber}, {headers}).pipe(
      map(response => response.available)
    );
  }

  private requestHeader(auth: string = ''): HttpHeaders {
    return new HttpHeaders({
      Authorization: auth,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
  }
}
