import { LocalesEnum } from '~core/modules/l10n/constants/locales.enum';
import { APP_CONFIG } from '~shared/constants/config';

const serviceBaseUrls = APP_CONFIG.serviceBaseUrls;
const captchaSiteKey = APP_CONFIG.captchaSiteKey;
const logLevel = APP_CONFIG.logLevel;
const featureConfig = APP_CONFIG.featureConfig;
const idamConfig = APP_CONFIG.idamConfig;
const appStaticUrl = APP_CONFIG.appStaticUrl;
const sentry = APP_CONFIG.sentry;
const configCatSdkKey = APP_CONFIG.configCatSdkKey;
const icecatConfig = APP_CONFIG.icecatConfig;
const discountCodeSellerId = APP_CONFIG.discountCodeSellerId;
const mmUrlDE = APP_CONFIG.mmUrlDE;
const mmUrlES = APP_CONFIG.mmUrlES;
const mmUrlIT = APP_CONFIG.mmUrlIT;
const mmUrlPT = APP_CONFIG.mmUrlPT;
const mmUrlNL = APP_CONFIG.mmUrlNL;
const mmUrlFR = APP_CONFIG.mmUrlFR;
const appMountPoint = APP_CONFIG.appMountPoint;
const metroSellerId = APP_CONFIG.metroSellerId;
const gaGtmId = APP_CONFIG.gaGtmId;

export const environment = {
  appId: 'app-employee',
  production: false,
  locale: APP_CONFIG.locale || LocalesEnum.en,
  hreflang: APP_CONFIG.hreflang,
  test: false,
  logLevel,
  featureConfig,
  cdnBaseUrl: serviceBaseUrls.cdn,
  svcPlatformBaseUrl: serviceBaseUrls.platform,
  svcCmsBaseUrl: serviceBaseUrls.cms,
  svcImsBaseUrl: serviceBaseUrls.ims,
  svcSellerOfficeBaseUrl: serviceBaseUrls.sellerOffice,
  svcSellerPimBaseUrl: serviceBaseUrls.sellerPim,
  svcSellerInventoryBaseUrl: serviceBaseUrls.sellerInventory,
  svcUserAccountBaseUrl: serviceBaseUrls.userAccount,
  appBuyerAccountBaseUrl: serviceBaseUrls.buyerAccount,
  svcCheckoutBaseUrl: serviceBaseUrls.checkout,
  svcStorefrontBaseUrl: serviceBaseUrls.storefront,
  svcSearchBaseUrl: serviceBaseUrls.search,
  svcGenericBaseUrl: serviceBaseUrls.generic,
  svcOrderManagementBaseUrl: serviceBaseUrls.orderManagement,
  svcEmployeeBaseUrl: serviceBaseUrls.employee,
  svcPaymentBaseUrl: serviceBaseUrls.payment,
  svcInvoicingBaseUrl: serviceBaseUrls.invoicing,
  svcAftersalesBaseUrl: serviceBaseUrls.aftersales,
  svcAnonymousEmailsBaseUrl: serviceBaseUrls.anonymousEmails,
  svcRefundRequestsBaseUrl: serviceBaseUrls.refundRequests,
  svcCategoryBaseUrl: serviceBaseUrls.category,
  svcDiscountBaseUrl: serviceBaseUrls.discount,
  vendorPortalBaseUrl: serviceBaseUrls.vendorPortalUrl,
  financialReportsUrl: serviceBaseUrls.financialReports,
  disputesBaseUrl: serviceBaseUrls.disputes,
  svcFraudBaseUrl: serviceBaseUrls.fraud,
  svcServicePimParsingBaseUrl: serviceBaseUrls.servicePimParsing,
  svcServicePimRowReviewUrl: serviceBaseUrls.servicePimRowReview,
  svcServicePimGoldenRecordMergeUrl: serviceBaseUrls.servicePimGoldenRecordMerge,
  svcServiceMMCUrl: serviceBaseUrls.serviceMMC,
  captchaSiteKey,
  idamConfig,
  appStaticUrl,
  sentryDsn: sentry.dsn,
  sentryEnabled: sentry.enabled,
  sentryRelease: sentry.release,
  configCatSdkKey,
  svcAccountingBaseUrl: serviceBaseUrls.accounting,
  icecatConfig,
  discountCodeSellerId,
  appMountPoint,
  mmUrlDE,
  mmUrlES,
  mmUrlIT,
  mmUrlPT,
  mmUrlNL,
  mmUrlFR,
  metroSellerId,
  svcModifiedInvoicingBaseUrl: serviceBaseUrls.modifiedInvoicing,
  gaGtmId,
  svcDunningBaseUrl: serviceBaseUrls.serviceDunning
};
