const PLACED_ORDER_TYPE = 1;
const CANCELED_ORDER_TYPE = 2;
const CONFIRMED_ORDER_TYPE = 3;
const SHIPPED_ORDER_TYPE = 4;
const WAITING_FOR_PAYMENT_ORDER_TYPE = 5;
const STATUS_RETURN_REQUEST = 6;
const STATUS_RETURN_ACCEPTED = 7;
const STATUS_RETURN_DECLINED = 8;
const STATUS_PAID = 9;
const STATUS_WAITING_FOR_APPROVAL = 12;
const MIXED_ORDER_TYPE = 100;

export enum OrderStateTypes {
  Placed = PLACED_ORDER_TYPE,
  Canceled = CANCELED_ORDER_TYPE,
  Confirmed = CONFIRMED_ORDER_TYPE,
  Shipped = SHIPPED_ORDER_TYPE,
  WaitingForPayment = WAITING_FOR_PAYMENT_ORDER_TYPE,
  ReturnRequested = STATUS_RETURN_REQUEST,
  ReturnAccepted = STATUS_RETURN_ACCEPTED,
  ReturnDeclined = STATUS_RETURN_DECLINED,
  Paid = STATUS_PAID,
  WaitingForApproval = STATUS_WAITING_FOR_APPROVAL,
  Mixed = MIXED_ORDER_TYPE,
}
