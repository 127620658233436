export enum FeatureToggleEnum {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  FF_EXTRA_LOGIN_PARAMETERS = 'FF_EXTRA_LOGIN_PARAMETERS',
  FF_TEMP_MPGD_12324_WAREHOUSES = 'ff_temp_mpgd_12324_warehouses',
  FF_OLC_456_NOTIFY_FRAUD = 'FF_OLC_456_NOTIFY_FRAUD',
  FF_TEMP_MPGD_16503_DISPLAY_CALCULATE_REFUND = 'FF_TEMP_MPGD_16503_DISPLAY_CALCULATE_REFUND',
  FF_ADD_NEW_FIELD_FOR_COMPANY_REPRESENTATIVE_JOB_TITLE = 'FF_ADD_NEW_FIELD_FOR_COMPANY_REPRESENTATIVE_JOB_TITLE',
  FF_TEMP_MPGD_17469_ADYEN_UPDATES = 'FF_TEMP_MPGD_17469_ADYEN_UPDATES',
  FF_TEMP_MPGD_18631_SOLE_TRADER_NATIONALITY_DROPDOWN = 'FF_TEMP_MPGD_18631_SOLE_TRADER_NATIONALITY_DROPDOWN',
  FF_MPGD_18738_STRICT_IBAN_AND_BIC_VALIDATION = 'FF_MPGD_18738_STRICT_IBAN_AND_BIC_VALIDATION',
  FF_MPGD_18917_ENABLE_ELECTRONICS_WEEE_INPUT_FIELD = 'FF_MPGD_18917_ENABLE_ELECTRONICS_WEEE_INPUT_FIELD',
  FF_TEMP_MPGD_21491_ENABLE_DELETE_BENEFICIAL_OWNER = 'FF_TEMP_MPGD_21491_ENABLE_DELETE_BENEFICIAL_OWNER',
  PIM_TEMP_USE_PDO_63_EXTRACT_SERVICE_PIM_PARSING = 'PIM_TEMP_USE_PDO_63_EXTRACT_SERVICE_PIM_PARSING',
  FF_ENABLE_USER_PERMISSIONS = 'FF_ENABLE_USER_PERMISSIONS',
  FF_TEMP_MPGD_25965_VAS_SERVICE = 'FF_TEMP_MPGD_25965_VAS_SERVICE',
  FF_REFUND_REQUESTS_MIGRATION = 'FF_REFUND_REQUESTS_MIGRATION',
  FF_BO_SILENT_REFRESH_TOKEN = 'FF_BO_SILENT_REFRESH_TOKEN',
  FF_TEMP_GET_ORDERS_V2 = 'FF_TEMP_GET_ORDERS_V2',
  FF_PAYMENT_DATA_LIST = 'FF_PAYMENT_DATA_LIST',
  FF_PIMPCR_762_ENABLE_UPLOAD_STATS = 'FF_PIMPCR_762_ENABLE_UPLOAD_STATS',
  FF_PIMPCR_998_ENABLE_UPLOAD_STATS_IN_APPROVED = 'FF_PIMPCR_998_ENABLE_UPLOAD_STATS_IN_APPROVED',
  FF_PIMPCR_998_ENABLE_UPLOAD_STATS_IN_AUTOPROCESSED = 'FF_PIMPCR_998_ENABLE_UPLOAD_STATS_IN_AUTOPROCESSED',
  PDS_493_PRODUCT_LIST_VIEW = 'PDS_493_PRODUCT_LIST_VIEW',
  SLACK_CHANNEL_BACKOFFICE_PERMISSIONS = 'SLACK_CHANNEL_BACKOFFICE_PERMISSIONS',
  SLACK_CHANNEL_LINK_BACKOFFICE_PERMISSIONS = 'SLACK_CHANNEL_LINK_BACKOFFICE_PERMISSIONS',
  ENABLE_DEBT_RECONCILIATION_MENU = 'ENABLE_DEBT_RECONCILIATION_MENU',
  FF_PIMPCR_1020_ENABLE_UPLOADS_V1 = 'FF_PIMPCR_1020_ENABLE_UPLOADS_V1',
  FF_PIMPCR_1020_ENABLE_UPLOADS_V2 = 'FF_PIMPCR_1020_ENABLE_UPLOADS_V2',
  FF_PIMPCR_1130_UPLOADS_V2_REFRESH_INTERVAL = 'FF_PIMPCR_1130_UPLOADS_V2_REFRESH_INTERVAL',
  FF_PIMPCR_1047_IS_REPORT_ENABLED = 'FF_PIMPCR_1047_IS_REPORT_ENABLED',
  FF_SOM1782_NEW_BACKOFFICE_KYC_TABLE = 'FF_SOM1782_NEW_BACKOFFICE_KYC_TABLE',
  FF_PIMPCR_1278_IS_BULK_REJECTION_ENABLED = 'FF_PIMPCR_1278_IS_BULK_REJECTION_ENABLED',
  FF_PDS_755_DELETE_KEY_FEATURES = 'FF_PDS_755_DELETE_KEY_FEATURES',
  FF_PIMPCR_1508_ENABLE_LOVS_PAGES_V2 = 'FF_PIMPCR_1508_ENABLE_LOVS_PAGES_V2',
}
